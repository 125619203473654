var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'},[_c('h1',{staticClass:"text-h5 q-pb-md q-my-md text-left fields-tab-header"},[_vm._v(" Import file ")]),(_vm.is_root_cg && _vm.current_CG.customer_group.manual_import_template_settings)?_c('div',{staticClass:"text-h6 text-left"},[_vm._v(" Use previously saved import settings? "),_c('q-toggle',{model:{value:(_vm.use_manual_import_template),callback:function ($$v) {_vm.use_manual_import_template=$$v},expression:"use_manual_import_template"}})],1):_vm._e(),_c('div',{staticClass:"text-left"},[_c('import-settings',{attrs:{"import_settings":_vm.template_settings,"table_column_options":_vm.table_column_options,"file":_vm.template_settings.file_headers
					? {
							file_headers: _vm.template_settings.file_headers.headers,
							file_data: _vm.template_settings.file_data,
							file_name: _vm.template_settings.file_name,
						}
					: null,"delete_button":false,"manual_import":true,"hide_action_buttons":_vm.hide_action_buttons},on:{"fileUpdate":_vm.analyzeFile,"import":_vm.import_file,"cancel":_vm.cancel_import,"update":_vm.update,"saveTemplate":_vm.saveTemplate}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }