<template>
	<div :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'">
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Import file
		</h1>

		<div v-if="is_root_cg && current_CG.customer_group.manual_import_template_settings" class="text-h6 text-left">
			Use previously saved import settings?
		<q-toggle v-model="use_manual_import_template"/>
		</div>

		<div class="text-left">
			<import-settings
				:import_settings="template_settings"
				:table_column_options="table_column_options"
				:file="
					template_settings.file_headers
						? {
								file_headers: template_settings.file_headers.headers,
								file_data: template_settings.file_data,
								file_name: template_settings.file_name,
							}
						: null
				"
				:delete_button="false"
				:manual_import='true'
				:hide_action_buttons='hide_action_buttons'
				@fileUpdate="analyzeFile"
				@import="import_file"
				@cancel="cancel_import"
				@update="update"
				@saveTemplate="saveTemplate"
			/>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import ImportSettings from '@/components/datasets/edit/sftp/ImportSettings.vue';
import { mapGetters, mapActions } from 'vuex';
import helpers from '@/utils/helpers.js'
import DatasetsAPI from '@/services/api/Datasets.js'
import { QSpinnerGears } from 'quasar'

export default {
	name: 'ImportUsers',
	components: {
		ImportSettings
	},
	data() {
		return{
			is_file_loaded: false,
			is_unique_emails: true,
			keep_admins_from_demoting: true,
			allow_new_data_to_be_created: false,
			use_manual_import_template: false,
			manual_template_data: null,
			file_data: [],
			file_headers: [],
			import_type: "",
			template_settings: {
				customer_group: '',
				external_id: 'true',
				gpg_encrypted: 'false',
				import_by_column: '',
				keep_admins_from_demoting: 'true',
				allow_new_field_options_to_be_created: false,
				import_type: '',
				template_name: {},
			},
			table_column_options: [],
			file: null,
			hide_action_buttons: true
		}
	},
	computed: {
		...mapGetters(['logged_user', 'is_root_cg', 'current_CG'])
	},
	methods: {
		...mapActions([
			'getCustomerGroups',
		]),
		async buildTableColumnOptions() {
			this.table_column_options = await helpers.buildSelectionListOptions({
				import_type: this.template_settings.import_type,
				column: this.template_settings.import_by_column,
				cg: this.template_settings.customer_group,
				external_id: this.template_settings.external_id,
				gpg_encrypted: this.template_settings.gpg_encrypted,
			})
		},
		calculatePercentage(progress_event, wording){
			let percentage = Math.ceil((progress_event.loaded / progress_event.total) * 100)
			this.$q.loading.show({
				message: `${wording} ${percentage}%`,
				spinner: QSpinnerGears,
			})
		},
		async analyzeFile(val) {
			if(!val){
				return
			}
			this.hide_action_buttons = false
			this.file = val
			let form_data = new FormData()
			form_data.append('file', val)
			const response = await DatasetsAPI.analyzeFile(form_data, this.calculatePercentage, false)
			async function sleep() {await new Promise(resolve => setTimeout(resolve, 1000));}
			await sleep();
			this.$q.loading.show({
				message: `Analysing the file ... 100%`,
				spinner: QSpinnerGears,
			})
			this.$q.loading.hide()
			if(response.error){
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				setTimeout(() => {
					this.cancel_import()
				}, 5000);
				return
			}

			this.template_settings = {
				...this.template_settings,
				file_headers: response.file_headers,
				file_data: response.file_data,
			}
		},
		async update(settings) {
			this.template_settings = settings
			await this.buildTableColumnOptions()
		},
		async import_file(rules){
			let mapping_rules = [];
			for(let [_, rule] of Object.entries(rules)){
				mapping_rules.push(rule.toString())
			}

			let form_data = new FormData();
			form_data.append('mapping_rules', JSON.stringify(mapping_rules));
			form_data.append("import_to_customer_group_id", this.template_settings.customer_group);
			form_data.append("import_by_column", this.template_settings.import_by_column);
			form_data.append("import_type", this.template_settings.import_type);
			form_data.append("keep_admins_from_demoting", this.template_settings.keep_admins_from_demoting)
			form_data.append('allow_new_field_options_to_be_created', this.template_settings.allow_new_field_options_to_be_created);
			form_data.append("file", this.file);

			this.$q.loading.show({
				delay: 400,
			})
			try {
				const response = await axios.post(
					'/api/user/new_import/',
					form_data,
					{onUploadProgress: progress_event => this.calculatePercentage(progress_event, "Processing request ...")}
				)
				this.$q.notify(response.data)
			} catch (error) {
				this.$q.notify({
					timeout: 6700,
					message: error.response.data.message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			}
			this.$q.loading.hide()
		},
		async saveTemplate(rules){

			let data = {
				'rules': JSON.stringify(rules),
				"customer_group": this.template_settings.customer_group,
				"import_by_column": this.template_settings.import_by_column,
				"import_type": this.template_settings.import_type,
				"keep_admins_from_demoting": this.template_settings.keep_admins_from_demoting,
				'allow_new_field_options_to_be_created': this.template_settings.allow_new_field_options_to_be_created
			}
			try {
				const response = await axios.post('/api/user/manual_import_template/', data)
				this.$q.notify(response.data)
			} catch (error) {
				this.$q.notify({
					timeout: 6700,
					message: error.response.data,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			}
		},
		cancel_import(){
			this.$router.go()
		},
	},
	async created() {
		await this.getCustomerGroups()
		await this.buildTableColumnOptions()
	},
	watch: {
		'use_manual_import_template': async function(){
			if (this.use_manual_import_template){
				try {
					const response = await axios.get(`/api/user/manual_import_template/${this.logged_user.customer_group}/`)
					this.manual_template_data = response.data["manual_import_template_settings"]
					this.template_settings = {
						...this.manual_template_data,
						file_data: this.template_settings.file_data,
						file_headers: this.template_settings.file_headers
					}
					await this.buildTableColumnOptions()
				} catch (error) {
					this.manual_template_data = null
				}
			}
			else {
				this.template_settings = {
					customer_group: '',
					external_id: 'true',
					gpg_encrypted: 'false',
					import_by_column: '',
					keep_admins_from_demoting: 'true',
					allow_new_field_options_to_be_created: false,
					import_type: '',
					template_name: {},
					file_data: this.template_settings.file_data,
					file_headers: this.template_settings.file_headers
				}
				await this.buildTableColumnOptions()
				this.manual_template_data = null
			}
		}
	},
	destroyed() {
	}
}

</script>

<style>

</style>


